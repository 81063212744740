<script setup>
import {
  defineComponent,
  defineProps,
  ref,
  reactive,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

const props = defineProps(["list"]);
const list = ref([]);
const getSwite = async () => {
  await nextTick();
  const res = await useFetch(constants.BASE_URL + api.getBannerList, 
    { method: 'GET'})
    console.log(res,'list');
    list.value= res?.data?._rawValue?.data
    console.log(list.value,'list');
}
getSwite()
const currentIndex = ref(0);

const activeImages = computed(() => list.value[currentIndex.value]);

const prevImage = () => {
  currentIndex.value = (currentIndex.value - 1 + list.value.length) % list.value?.length;
  console.log('123',currentIndex.value,list);
};

const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % list.value?.length;
    console.log('123',currentIndex.value);
};

const goPay = (item) => {
  console.log(item);
  navigateTo('/product/'+ item.goodsCode);
}



onUnmounted(() => {
});
</script>

<template>
  <div class="carousel-container" v-if="list">
    <div class="carousel-wrapper">
      <NuxtImg class="img" :src="activeImages?.file?.fileUrl" alt="Carousel Image" />
      <div class="carousel-conntext">
        <div class="title">{{activeImages?.title}}</div>
        <div class="describe">{{activeImages?.describe}}</div>
        <img class="buy" src="../../assets/imgs/index/buy.png" alt="" @click="goPay(activeImages)"/>
      </div>
      <div class="carousel-controls">
        <img src="../../assets/imgs/index/go1.png" @click="prevImage"></img>
        <img src="../../assets/imgs/index/go2.png" @click="nextImage"></img>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.carousel-container {
  width: 100%;
  height: 800px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.carousel-wrapper img {
    width: 100%;
    height: 40%;
}

.carousel-controls {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.carousel-controls img {
  width: 2.6vw;
  height: 2.6vw;
  line-height: 1.5vw;
  color: #fff;
  border: none;
  cursor: pointer;
}

.carousel-conntext  {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
 .title{
  font-size: 5.2vw;
  color: #fff;
  line-height: 6.25vw;
  margin-bottom: 40px;
 }
 .describe {
  height: 2.7vw;
  font-size: 2vw;
  color: #fff;
  line-height: 2.7vw;
  text-align: center;
 }
 .buy {
  width: 7.5vw;
  height: 2.7vw;
  margin-top: 6.7vw;
  cursor: pointer;
 }
}
</style>
