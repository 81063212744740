<script setup>
const props = defineProps([]);
let lists = ref({});
const boxref = ref(null);

const getBestSell = async () => {
    await nextTick();
    const res = await useFetch(constants.BASE_URL + api.getApplications,
        { method: 'GET' })
    console.log(res, 'list4');
    lists.value = res.data.value.data[0]
    console.log(lists.value, 'list2');
}
getBestSell()
const prevImage = () => {
    if (boxref.value) {
        const currentScrollLeft = boxref.value.scrollLeft;
        boxref.value.scrollTo({
            left: currentScrollLeft - 400,
            behavior: 'smooth'
        });
    }
};

const nextImage = () => {
    if (boxref.value) {
        const currentScrollLeft = boxref.value.scrollLeft;
        boxref.value.scrollTo({
            left: currentScrollLeft + 400,
            behavior: 'smooth'
        });
    }
};

const goBanner = (item) => {
    window.location.href = item.clickUrl
}
</script>

<template>
    <div class="Module4" v-if="lists">
        <div class="datitle">{{ lists.moduleName }}</div>
        <div class="m4-box">
            <div class="box" ref="boxref">
                <div class="box-item" v-for="item in lists?.children" @click="goBanner(item)">
                    <div class="box-item-b">
                        <NuxtImg class="videos" :src="item?.children[0]?.file?.fileUrl" />
                        <div class="carousel-conntext">
                            <div class="title">{{ item?.moduleName }}</div>
                            <!-- <div class="describe">{{ item?.moduleDescribe }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="kzq2">
            <img src="../../assets/imgs/index/go1.png" @click="prevImage"></img>
            <img src="../../assets/imgs/index/go2.png" @click="nextImage"></img>
        </div>
    </div>
</template>

<style lang="less" scoped>
.Module4 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;

    /* 隐藏滚动条的通用样式 */
    ::-webkit-scrollbar {
        width: 0;
    }

    .kzq2 {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        bottom: 40%;
        left: 0;
        z-index: 99;
        padding: 0 24px;
        cursor: pointer;

    }

    .datitle {
        margin: 100px 0 24px 160px;
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
    }

    .m4-box {
        width: 1600px;
        margin: 0 auto;

        .box {
            width: 100%;
            display: flex;
            position: relative;
            overflow-x: auto;

            .box-item {
                width: 480px;
                height: 610px;
                color: #ffffff;
                position: relative;
                margin-right: 24px;
                cursor: pointer;

                .box-item-b {
                    overflow: hidden;
                    border-radius: 20px;

                    .videos {
                        width: 480px;
                        height: 600px;
                        border-radius: 20px;
                        position: relative;
                        z-index: 999;
                    }

                    .videos:hover {
                        transform: scale(1.2);
                        transition: transform 0.3s ease;
                    }

                    .carousel-conntext {
                        position: absolute;
                        top: 498px;
                        left: 40px;
                        z-index: 999;
                        .title {
                            font-size: 32px;
                            font-weight: 700;
                            margin-top: 15px;
                        }

                        .describe {
                            font-size: 24px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

            }
        }
    }


}
</style>
