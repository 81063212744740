<script setup>
import {
  defineProps,
  ref,
} from "vue";

const props = defineProps(["list"]);

const lists = ref([]);
const Software = ref([]);
const LiDAR360MLS = ref([]);
const Services = ref([]);
const getGoodsShow = async () => {
  await nextTick();
  const res = await useFetch(constants.BASE_URL + api.getGoodsShow,
    { method: 'GET' })
  console.log(res, 'list3');
  lists.value.push(...res?.data?._rawValue?.data)
  Software.value = lists.value[0]
  LiDAR360MLS.value = lists.value[1]
  Services.value = lists.value[2]
  console.log(lists.value,Software.value,LiDAR360MLS.value,Services.value, 'list3');
}
getGoodsShow()
const currentIndex = ref(0);
console.log(lists.value, "props333");

console.log(Software, LiDAR360MLS, Services, "Software");

const goBanner = (item,show=false) => {
  console.log(item, 'item============');
  if(show) {
    navigateTo('/product/'+item.moduleCode)
    return
  }
  if (item?.clickUrl) {
    window.location.href = item.clickUrl
  } else {
    navigateTo('/product/'+item.goodsCode)
  }
}
</script>

<template>
  <div class="Module3" v-if="lists">
    <!-- Software -->
     <div v-for="items in lists">
      <div class="kuai" >
      <div class="datitle">{{ items?.moduleName }}<span v-if="items?.title">:{{ items?.title }}</span></div>
      <div class="box">
        <div class="box-v">
          <video loop muted autoplay v-if="items?.vedioFile?.fileType == 'mp4'" style="width: 1600px;height: 658px;" class="img-v"
            :src="items?.vedioFile?.fileUrl"></video>
          <NuxtImg v-if="items?.vedioFil?.fileType == 'png'" class="img-v" :src="items?.vedioFile?.fileUrl"
            alt="Carousel Image" />
        </div>
        <div class="box-item">
          <div class="box-itemc" v-for="item in items?.goods" @click="goBanner(item)">
            <div class="box-itemc-b">
              <NuxtImg class="img2" :src="item?.file?.fileUrl" alt="Carousel Image" />
              <div class="context2">
                <span class="t2">{{ item?.goodsName }}</span>
                <span class="d2" v-if="item?.price">From USD ${{ item?.price }}</span>
              </div>
            </div>
          </div>
          <div class="box-itemb" >
            <div class="box-itemb-b" @click="goBanner(items)">
              <NuxtImg class="img" :src="items?.guideFile?.fileUrl" alt="Carousel Image" />
              <div class="context1">
                <span class="t1">{{ items?.guideTitle }}</span>
                <span class="d1">{{ items?.guideDesc }}</span>
              </div>
            </div>
            <div class="btn" @click="goBanner(items,true)">More Module<img src="/assets/imgs/index/right.png" alt=""/></div>
          </div>
        </div>
      </div>
    </div>
     </div>


    <!-- LiDAR360MLS -->
    <!-- <div class="kuai"  v-if="LiDAR360MLS">
      <div class="datitle">{{ LiDAR360MLS?.moduleName }}</div>
      <div class="box">
        <div class="box-v">
          <video loop muted autoplay v-if="LiDAR360MLS?.file?.fileType == 'mp4'" style="width: 1600px;height: 658px;"
            :src="LiDAR360MLS?.file?.fileUrl"></video>
          <NuxtImg v-if="(LiDAR360MLS?.file?.fileType == 'png' || LiDAR360MLS?.file?.fileType == 'jpg')" class="img-v" :src="LiDAR360MLS?.file?.fileUrl"
            alt="Carousel Image" />
        </div>
        <div class="box-item">
          <div class="box-itemc" v-for="item in LiDAR360MLS?.module?.goods" @click="goBanner(item)">
            <div class="box-itemc-b">
              <NuxtImg class="img2" :src="item?.file?.fileUrl" alt="Carousel Image" />
              <div class="context2">
                <span class="t2">{{ item?.goodsName }}</span>
                <span class="d2">From USD ${{ item?.price }}</span>
              </div>
            </div>

          </div>
          <div class="box-itemb" @click="goBanner(LiDAR360MLS?.module)">
            <div class="box-itemb-b">
              <NuxtImg class="img" :src="LiDAR360MLS?.module?.file?.fileUrl" alt="Carousel Image" />
              <div class="context1">
                <span class="t1">{{ LiDAR360MLS?.module?.title }}</span>
                <span class="d1">{{ LiDAR360MLS?.module?.desc }}</span>
              </div>
            </div>
            <div class="btn">More Module <img src="/assets/imgs/index/right.png" alt=""/></div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Services -->
    <!-- <div class="kuai2"  v-if="Services">
      <div class="datitle">{{ Services?.moduleName }}</div>
      <div class="services">
        <div class="s-box" v-for="item in Services?.module?.goods" @click="goBanner(item)">
          <div class="s-box-b">
            <NuxtImg class="img3" :src="item?.file?.fileUrl" alt="Carousel Image" />
            <div class="context3">
              <span class="t3">{{ item?.goodsName }}</span>
              <span class="d3">From USD ${{ item?.price }}</span>
            </div>
          </div>

        </div>
      </div>
    </div> -->
  </div>



</template>

<style lang="less" scoped>
.Module3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;
  max-width: 1600px;
  margin: 0 auto;

  .kuai {
    width: 1600px;

    .datitle {
      font-size: 36px;
      margin: 100px 0 24px 0px;
      font-weight: 700;
      color: #ffffff;
    }

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 1;

      .box-v {
        img-v {
          width: 100%;
          height: 658px;
          border-radius: 20px;
        }
      }

      .box-item {
        width: 100%;
        min-width: 1600px;
        z-index: 1;
        border-radius: 20px;
        background: #000000;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .box-itemb {
          position: relative;
          cursor: pointer;
          .btn {
            width: 458px;
            height: 128px;
            border-radius: 20px;
            background: linear-gradient(to right, #0189be, #32a395);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 36px;
            font-weight: 600;
            cursor: pointer;
            margin-top: 12px;
            img {
              margin-left: 20px;
            }
          }
          .box-itemb-b {
            width: 458px;
            height: 430px;
            border-radius: 20px;
            overflow: hidden;

            .img {
              width: 458px;
              height: 572px;
              border-radius: 20px;
            }

            .img:hover {
              transform: scale(1.1);
              transition: transform 0.3s ease;
            }

            .context1 {
              position: absolute;
              top: 56px;
              left: 35px;
              color: #ffffff;
              width: 80%;
              height: 150px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .t1 {
                font-size: 36px;
                margin-bottom: 20px;
              }

              .d1 {
                font-size: 42px;
                font-weight: 600;
              }
            }
          }

        }

        .box-itemc {
          width: 22%;
          height: 572px;
          position: relative;
          cursor: pointer;

          .box-itemc-b {
            overflow: hidden;
            border-radius: 20px;
            width: 100%;
            height: 100%;

            .img2 {
              width: 100%;
              height: 100%;

            }

            .img2:hover {
              transform: scale(1.1);
              transition: transform 0.3s ease;
            }


            .context2 {
              position: absolute;
              top: -160px;
              left: 33px;
              color: #ffffff;
              width: 80%;
              height: 115px;
              display: flex;
              flex-direction: column;
              position: relative;

              .t2 {
                font-size: 34px;
                margin-bottom: 39px;
              }

              .d2 {
                font-size: 18px;
                width: 200px;
                overflow: hidden;
                position: absolute;
                text-overflow: ellipsis;
                bottom: 0;
              }
            }
          }

        }
      }
    }
  }

  .kuai2 {
    width: 1600px;

    .datitle {
      font-size: 36px;
      margin: 100px 0 24px 0px;
      font-weight: 700;
      color: #ffffff;
    }

    .services {
      display: flex;
      width: 84%;
      z-index: 1;
      justify-content: start;


      .s-box {
        height: 658px;
        border-radius: 20px;
        margin-top: 30px;
        margin-right: 17px;
        position: relative;
        cursor: pointer;

        .s-box-b {
          width: 357px;
          height: 572px;
          overflow: hidden;
          border-radius: 20px;

          .img3 {
            width: 357px;
            height: 572px;
            border-radius: 20px;
          }

          .img3:hover {
            transform: scale(1.1);
            transition: transform 0.3s ease;
          }


          .context3 {
            position: absolute;
            top: -160px;
            left: 33px;
            color: #ffffff;
            width: 100%;
            height: 115px;
            display: flex;
            flex-direction: column;
            position: relative;

            .t3 {
              width: 250px;
              font-size: 34px;
              margin-bottom: 39px;
            }

            .d3 {
              font-size: 18px;
              width: 200px;
              height: 25px;
              overflow: hidden;
              position: absolute;
              bottom: 0;
              text-overflow: ellipsis;
            }
          }
        }

      }
    }
  }
}
</style>
